<template>
  <div class="warpbg">

        
    <div class="frame_rank">
      <div class="number_text">第二届</div>
      <div class="box_rank">
        <div class="rank_1">
          <img v-if="rankList[0]"
            class="rank_1_avatar"
            :src="rankList[0]['avatar']"
            alt=""
          >
          <div class="rank_text rank_1_text">
            <div
              class="rank_1_role rank_small_font"
              v-if="rankList[0]"
            >{{ rankList[0]['server'] }}</div>
            <div
              class="rank_1_money rank_small_font"
              v-if="rankList[0]"
            >{{ rankList[0]['role_name'] }}</div>
            <!-- <div class="rank_1_role rank_small_font">雪花1区 帝王将相</div>
                        <div class="rank_1_money rank_small_font">充值金额 10000</div> -->
          </div>

        </div>
        <div class="rank_2-5 rank_2">
          <img v-if="rankList[1]"
            class="rank_2-5_avatar rank_2_avatar"
            :src="rankList[1]['avatar']"
            alt=""
          >
          <div class="rank_text rank_2-5_text ">
            <div
              class="rank_2-5_small_font"
              v-if="rankList[1]"
            >{{ rankList[1]['server'] }}</div>
            <div
              class="rank_2-5_money rank_2-5_small_font"
              v-if="rankList[1]"
            >{{ rankList[1]['role_name'] }}</div>
            <!-- <div class="rank_2-5_small_font">雪花1区 帝王将相</div>
            <div class="rank_2-5_money rank_2-5_small_font">充值金额 10000</div> -->
          </div>
        </div>
        <div class="rank_2-5 rank_3">
          <img v-if="rankList[2]"
            class="rank_2-5_avatar rank_3_avatar"
            :src="rankList[2]['avatar']"
            alt=""
          >
          <div class="rank_text rank_2-5_text ">
            <div
              class="rank_2-5_small_font"
              v-if="rankList[2]"
            >{{ rankList[2]['server'] }}</div>
            <div
              class="rank_2-5_money rank_2-5_small_font"
              v-if="rankList[2]"
            >{{ rankList[2]['role_name'] }}</div>
          </div>
        </div>
        <div class="rank_2-5 rank_4">
          <img v-if="rankList[3]"
            class="rank_2-5_avatar rank_4_avatar"
            :src="rankList[3]['avatar']"
            alt=""
          >
          <div class="rank_text rank_2-5_text ">
            <div
              class="rank_2-5_small_font"
              v-if="rankList[3]"
            >{{ rankList[3]['server'] }}</div>
            <div
              class="rank_2-5_money rank_2-5_small_font"
              v-if="rankList[3]"
            >{{ rankList[3]['role_name'] }}</div>
          </div>
        </div>
        <div class="rank_2-5 rank_5">
          <img v-if="rankList[4]"
            class="rank_2-5_avatar rank_5_avatar"
            :src="rankList[4]['avatar']"
            alt=""
          >
          <div class="rank_text rank_2-5_text ">
            <div
              class="rank_2-5_small_font"
              v-if="rankList[4]"
            >{{ rankList[4]['server'] }}</div>
            <div
              class="rank_2-5_money rank_2-5_small_font"
              v-if="rankList[4]"
            >{{ rankList[4]['role_name'] }}</div>
          </div>
        </div>

        <div class="box_comment">
          <vue-seamless-scroll
            :data="comment"
            class="box_comment_warp"
            :class-option="scrollClassOption"
          >
          <div v-for="(item, index) in comment"
                :key="index" class="comment_text" v-text="item" >
          </div>
          </vue-seamless-scroll>
        </div>

        <div class="date">{{ remark }}</div>

        <div class="rank_box_6-10">
          <div
            class="rank_6-10"
            v-for="(item, index) in rankList.slice(5, 10)"
            :key="index"
          >
            <div class="rank_6-10_text" v-if="item['total_money'] > 0">{{ item['server'] }}</div>
            <div class="rank_6-10_text" v-if="item['total_money'] > 0">{{ item['role_name'] }}</div>
            <div class="rank_6-10_wait_text" v-if="item['total_money'] <= 0">虚位以待</div>
          </div>
          <!-- <div class="rank_6-10">
            <div class="rank_6-10_text">雪花1区</div>
            <div class="rank_6-10_text">帝王将相</div>
          </div>
          <div class="rank_6-10">
            <div class="rank_6-10_text">雪花1区</div>
            <div class="rank_6-10_text">帝王将相</div>
          </div>
          <div class="rank_6-10">
            <div class="rank_6-10_text">雪花1区</div>
            <div class="rank_6-10_text">帝王将相</div>
          </div>
          <div class="rank_6-10">
            <div class="rank_6-10_text">雪花1区</div>
            <div class="rank_6-10_text">帝王将相</div>
          </div>
          <div class="rank_6-10">
            <div class="rank_6-10_text">雪花1区</div>
            <div class="rank_6-10_text">帝王将相</div>
          </div> -->

        </div>
      </div>
    </div>
    <div class="frame_gift">
      <div class="box_gift_rank">
        <div
          class="gift_rank_list"
          v-for="(item, index) in giftList"
          :key="index"
          v-show="index >= 0 && index <= 4"
        >
          <div
            class="gift_list"
            v-for="(gift, i) in item"
            :key="i"
            v-show="i >= 0 && i <= 4"
          >
            <div class="gift_img"><img :src="gift.prop_img_url"></div>
            
            <div class="prop_name">{{ gift.prop_name }}</div>
          </div>
        </div>
        <!-- <div class="gift_rank_list">
          <div class="gift_list">
            <img
              :src="require('@/assets/mrt/rank_1_avatar.jpg')"
              alt=""
            >
            <div class="prop_name">经验丹*1</div>

          </div>
          <div class="gift_list">
            <img
              :src="require('@/assets/mrt/rank_1_avatar.jpg')"
              alt=""
            >
            <div class="prop_name">经验丹*1</div>
          </div>
          <div class="gift_list">
            <img
              :src="require('@/assets/mrt/rank_1_avatar.jpg')"
              alt=""
            >
            <div class="prop_name">传奇武器*1</div>
          </div>
          <div class="gift_list">
            <img
              :src="require('@/assets/mrt/rank_1_avatar.jpg')"
              alt=""
            >
            <div class="prop_name">经验丹*1</div>
          </div>
          <div class="gift_list">
            <img
              :src="require('@/assets/mrt/rank_1_avatar.jpg')"
              alt=""
            >
            <div class="prop_name">经验丹*1</div>
          </div>
        </div>
        <div class="gift_rank_list">
          <div class="gift_list">
            <img
              :src="require('@/assets/mrt/rank_1_avatar.jpg')"
              alt=""
            >
            <div class="prop_name">经验丹*1</div>
          </div>
          <div class="gift_list">
            <img
              :src="require('@/assets/mrt/rank_1_avatar.jpg')"
              alt=""
            >
            <div class="prop_name">经验丹*1</div>
          </div>
          <div class="gift_list">
            <img
              :src="require('@/assets/mrt/rank_1_avatar.jpg')"
              alt=""
            >
            <div class="prop_name">传奇武器*1</div>
          </div>
          <div class="gift_list">
            <img
              :src="require('@/assets/mrt/rank_1_avatar.jpg')"
              alt=""
            >
            <div class="prop_name">经验丹*1</div>
          </div>
          <div class="gift_list">
            <img
              :src="require('@/assets/mrt/rank_1_avatar.jpg')"
              alt=""
            >
            <div class="prop_name">经验丹*1</div>
          </div>
        </div> -->

      </div>
      <div class="box_desc">
        <div class="text" style="float:left">活动说明:</div>
        <!-- <div class="text">1 2017年09月18日00点至2017年09月20日24点的消费总额满25万元宝的玩家（前十名）将把自己的名字载入传奇霸业名人堂史册，并获得丰厚的奖励；</div>
        <div class="text">2 活动期间玩家消费总额满25万元宝（前十名以外）即可获得参与奖丰厚奖励；</div>
        <div class="text">3 消费前三名可获得loading展示（7天）</div>
        <div class="text">4 消费100W元宝以上，即可获得装备兑换特权：同等价值装备转换，如霸业神甲（男）转换成霸业神甲（女），同等价值装备法师戒指换战士戒指等</div> -->
        <!-- <p>1.2017年09月18日00点至2017年09月20日24点的消费总额满25万元宝的玩家（前十名）将把自己的名字载入传奇霸业名人堂史册，并获得丰厚的奖励；</p> 
        <p>2.活动期间玩家消费总额满25万元宝（前十名以外）即可获得参与奖丰厚奖励；</p>
        <p>3.消费前三名可获得loading展示（7天）</p>
        <p>4.消费100W元宝以上，即可获得装备兑换特权：同等价值装备转换，如霸业神甲（男）转换成霸业神甲（女），同等价值装备法师戒指换战士戒指等</p> -->
        <div v-html="desc" style="float:left"></div>
      </div>
    </div>

    <div class="frame_baberrage">
      <vue-baberrage
        :isShow="barrageIsShow"
        :barrageList="barrageList"
        :loop="barrageLoop"
      />
    </div>

  </div>

    
</template>

<script>

import { getMrt } from '@/api/mrt'
import vueSeamlessScroll from 'vue-seamless-scroll'
import { vueBaberrage, MESSAGE_TYPE } from 'vue-baberrage'

export default {
  components: {
    vueSeamlessScroll, vueBaberrage
  },
  data () {
    return {
      rankList: [],
      giftList: [],
      desc: undefined,
      remark: undefined,
      comment: [],
      scrollClassOption: {
        step:0.3,
        limitMoveNum: 1
      },
      barrageIsShow: true,
      barrageCurrentId: 0,
      barrageLoop: true,
      barrageList: []
    }
  },
  created () {
    this.initView()
    this.getMrtApi()
    this.handleBarrage()
  },
  methods: {
    initView () {
      let scale = 1.0 / window.devicePixelRatio;
      let text = `<meta name="viewport" content="width=device-width, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, user-scalable=no">`;
      document.write(text);
      document.documentElement.style.fontSize = window.innerWidth / 7.5 + "px";

    },
    getMrtApi () {
      getMrt({ number_id: 2 }).then((res) => {
        this.desc = '<p>' + res.desc.replace(/\n/g, '</p><p>') + '</p>'
        this.remark = res.remark
        this.rankList = res.mrt_ranks
        this.rankList.forEach((val, index) => {
          this.rankList[index]['money'] = parseInt(val['money'])
          if (!val['avatar']) {
            this.rankList[index]['avatar'] = require('@/assets/mrt/rank_1_avatar.jpg')
          }
        });
        this.giftList = res.mrt_gifts
        this.comment = res.comment.split(/\n/g)
      })
    },
    handleBarrage() {
      this.barrageList.push({
        id: ++this.barrageCurrentId,
        // avatar: "./static/avatar.jpg",
        msg: '是兄弟就来看我',
        time: 5,
        type: MESSAGE_TYPE.NORMAL
      })
      this.barrageList.push({
        id: ++this.barrageCurrentId,
        // avatar: "./static/avatar.jpg",
        msg: '是兄弟就来看我',
        time: 5,
        // type: vueBaberrage.NORMAL
      })
      this.barrageList.push({
        id: ++this.barrageCurrentId,
        // avatar: "./static/avatar.jpg",
        msg: '是兄弟就来看我',
        time: 5,
        // type: vueBaberrage.NORMAL
      })
      this.barrageList.push({
        id: ++this.barrageCurrentId,
        // avatar: "./static/avatar.jpg",
        msg: '是兄弟就来看我',
        time: 5,
        // type: vueBaberrage.NORMAL
      })

      console.log(this.barrageList);
    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.warpbg {
  width: 100%;
  height: 26.5rem;
}

.warpbg .frame_rank,
.warpbg .frame_gift {
  float: left;
  width: 100%;
}

.number_text {
  position:absolute;
  width: 0.38rem;
  height: 0.86rem;
  right: 1.80rem;
  top: 0.82rem;
  font-size: 0.25rem;
  color: rgb(249, 209, 87);
  font-weight: bold;
  text-align: center;
  line-height: 0.28rem;
}

.frame_rank {
  /* background: url('../../assets/mrt/background_01.jpg') top center no-repeat; */
  background: url('../../assets/mrt/background_up_v1.gif') top center no-repeat;
  height: 11.3rem;
  background-size: contain;
  position: relative;
  margin: 0 auto;
}
.frame_gift {
  /* background: url('../../assets/mrt/background_02.jpg') top center no-repeat; */
  background: url('../../assets/mrt/background_down_v1.gif') top center no-repeat;
  height:15.20rem;
  background-size: contain;
}
.box_rank {
  background: url('../../assets/mrt/user_rank_v1.gif') top center no-repeat;
  /* background: url('../../assets/mrt/03.gif') top center no-repeat; */
  margin-top: 2.38rem;
  height: 8.46rem;
  background-size: contain;
  z-index: 100;
  position: relative;
}
.rank_1 {
  position: absolute;
  top: 0.55rem;
  left: 2.88rem;
  width: 1.8rem;
  height: 2.3rem;
}
.rank_1_avatar {
  width: 1.06rem;
  height: 1.02rem;
  margin-top: 0.44rem;
  margin-left: 0.38rem;
  z-index: 200;
  float: left;
}
.rank_2-5 {
  position: absolute;
  width: 1.24rem;
  height: 1.59rem;
}
.rank_2-5_avatar {
  width: 0.74rem;
  height: 0.71rem;
  z-index: 200;
  float: left;
  margin-top: 0.3rem;
  margin-left: 0.26rem;
}
.rank_2 {
  top: 2.09rem;
  left: 0.9rem;
}
.rank_3 {
  top: 2.09rem;
  right: 0.87rem;
}
.rank_4 {
  top: 4.18rem;
  left: 0.9rem;
}
.rank_5 {
  top: 4.18rem;
  right: 0.87rem;
}
.rank_text {
  font-size: 0.3rem;
  color: rgb(249, 209, 87);
  text-align: center;
  display: block;
}
.rank_1_text {
  float: left;
  padding-top: 0.1rem;
  width: 1.8rem;
  height: 0.51rem;
}
.rank_small_font {
  position: absolute;
  left: 50%;
  display: block;
  white-space: nowrap;
  -webkit-transform: translate3d(-50%, 0, 0) scaleX(0.7) scaleY(0.7);
  transform: translate3d(-50%, 0, 0) scaleX(0.7) scaleY(0.7);
}
.rank_1_money {
  margin-top: 0.25rem;
}

.rank_2-5_text {
  float: left;
  width: 1.24rem;
  height: 0.35rem;
  padding-top: 0.05rem;
  font-size: 0.3rem;
  color: rgb(249, 209, 87);
  text-align: center;
  display: block;
}
.rank_2-5_small_font {
  position: absolute;
  left: 50%;
  display: block;
  white-space: nowrap;
  -webkit-transform: translate3d(-50%, 0, 0) scaleX(0.5) scaleY(0.5);
  transform: translate3d(-50%, 0, 0) scaleX(0.5) scaleY(0.5);
}
.rank_2-5_money {
  margin-top: 0.15rem;
}
.box_comment {
  background: url('../../assets/mrt/comment.gif') top center no-repeat;
  height: 2.1rem;
  width: 100%;
  z-index: 300;
  position: absolute;
  background-size: contain;
  padding-top: 0.35rem;

  margin-top: 3.42rem;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;;
  overflow: hidden;
}

.box_comment_warp {
  height: 1.56rem;
  width: 3rem;
  margin: 0 auto;
  overflow: hidden;
}

.comment_text {
  font-size: 0.25rem;
  color: rgb(249, 209, 87);
  /* display: block; */
  line-height: 0.4rem;
  /* position: relative;
  left: 50%; */
  -webkit-transform: translate3d(-00%, 0, 0) scaleX(0.8) scaleY(0.8);
  transform: translate3d(-00%, 0, 0) scaleX(0.8) scaleY(0.8);

  display: flex;
  justify-content: space-around;

  margin-bottom: -0.15rem;
}

.rank_box_6-10 {
  position: absolute;
  margin-top: 7rem;
  height: 1.07rem;
  /* width: 100%; */
  padding-left: 0.9rem;
}

.rank_6-10 {
  /* display: inline-block; */
  width: 0.52rem;
  height: 1.07rem;
  margin-right: 0.80rem;
  color: rgb(249, 209, 87);
  float: left;
}
.rank_6-10_text {
  float: left;
  margin: 0 auto;
  width: 0.26rem;
  height: 1.07rem;
  font-size: 0.2rem;

  display: block;
  -webkit-transform: translate3d(0, -10%, 0) scaleX(0.8) scaleY(0.8);
  transform: translate3d(0, -10%, 0) scaleX(0.8) scaleY(0.8);
}

.rank_6-10_wait_text {
  /* float: left; */
  padding-top: 0.2rem;
  margin: 0 auto;
  width: 0.26rem;
  height: 1.07rem;
  font-size: 0.2rem;
  display: block;
  transform: translate3d(0, -10%, 0) scaleX(0.9) scaleY(0.9);
}
.box_gift_rank {
  margin-top: 1.1rem;
  height: 5.9rem;
  padding-top: 0.38rem;
}
.gift_rank_list {
  /* width: 100%; */
  height: 0.7rem;
  margin-bottom: 0.5rem;
  padding-left: 0.2rem;
}

.gift_list {
  display: block;
  float: left;
  height: 0.7rem;
  width: 1.46rem;
  margin: 0 auto;
  text-align: center;
}
.gift_img {
  width: 0.5rem;
  height: 0.7rem;
  float: left;
}
.prop_name {
  float: left;
  color: white;
  font-size: 0.2rem;
  width: 0.96rem;
  /* white-space: nowrap; */
  -webkit-transform: translate3d(-12%, -15%, 0) scaleX(0.7) scaleY(0.7);
  transform: translate3d(-12%, -15%, 0) scaleX(0.7) scaleY(0.7);
}
.gift_list img {
  float: left;
  height: 0.42rem;
  width: 0.42rem;
  /* padding-left: 0.14rem; */
}

.box_desc {
  margin-left: 0.2rem;
  /* width:100%; */
  font-size: 0.25rem;
  color: rgb(249, 209, 87);
}
.box_desc .text {
  width: 7rem;
  float: left;
}

.date {
  position: absolute;
  margin-top: 5.9rem;
  font-size: 0.25rem;
  color: white;
  text-align: center;
  width: 100%;
  transform: translate3d(0%, 0, 0) scaleX(0.7) scaleY(0.7);
}

.box_desc p {
  margin: 0.15rem auto;
}
.frame_baberrage {
  position:absolute;
  top: 0.5rem;
  width: 100%;
  height: 3rem;
}

</style>